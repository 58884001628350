.footer {
    left:0;
    bottom:0;
    height: 100px;
    display: grid;
    grid-template-columns: 200px 200px;
    justify-items: center;
    justify-content: center;
}

.footer-link {
    display: block;
    vertical-align: top;
    color: white;
    text-decoration: none;
}

.footer-link:hover {
  color: var(--jiforange)
}

.footer-container {
    margin-top: 100px;
}

.footer-line {
    width: 60%;
    border-style: solid;
    border-color: var(--jifgrey);
}

.footer-text {
    margin: 0px;
    margin-bottom: 10px;
    color: var(--jiflightorange);
}
