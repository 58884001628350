.text-page-container {
    width: 60%;
    @media (max-width: 1350px){
        width: 90%;
    }
    background-color: var(--jifgrey);
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 30px;
}