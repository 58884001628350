.navbar {
    text-align: center;
    margin-bottom: 70px;
}

.navbar-link {
  background-color: ;
  border: none;
  color: white;
  padding: 10px 15px;
  margin-left: 5px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  outline: none;
}

.navbar-link:hover {
  box-shadow: 0 3px 0 0 var(--jiflightorange);
  cursor: pointer;
}

.navbar-link.active {
    box-shadow: 0 3px 0 0 var(--jiforange);
}