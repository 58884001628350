.commands-page {
    margin: auto;
    text-align: center;
}

.command-title {
    background-color: var(--jifdarkgrey);
    color: var(--jiforange);
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    margin-top: 18px;
}

.command-card {
    background-color: var(--jifgrey);
    padding-bottom: 10px;
    box-shadow: 0 3px var(--jifdarkgrey);
    border-radius: 20px;
    margin: 10px;
    width: 600px;
    display: inline-grid;
    text-align: left;
}

.command-description {
    color: white;
    padding-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.command-parameter {
    padding-left: 40px;
    margin-right: 20px
}

.parameter-text {
    margin-top: 5px;
    margin-bottom: 5px;
}

.category-select {
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
    width: 30%;
}