.home-header {
    text-align: center;
    color: var(--jiforange);
}

a.button {
    margin: 0 auto;
    display: block;
    background-color: var(--jiforange);
    border: none;
    color: var(--jifgrey);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-family: "Smallest Pixel-7";
    width: 80px;
}

a.button:hover {
    background-color: var(--jiflightorange);
}

.home-welcome {
    text-align: center;
    width: 60%;
    margin: 20px auto 20px auto;
    display: block;
    font-size: 20;
}

.cell {
    background-color: var(--jifdarkgrey);
    padding: 10px 10px 10px 10px;
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
}

.cell-header {
    color: var(--jiflightorange);
}

.cell-image{
    display: block;
    object-fit: contain;
    max-width: 40%;
}

.cell-text{
    padding-left: 10px;
    padding-right: 10px;
}